import { OrderStatus, ErrorCode } from '@triplabo/web/services/types';
export default {
  views: {
    dashboard: {
      title: '仪表板'
    },
    home: {
      greeting: '嗨，我们出发吧!',
      go: '龙头打车平台GO提供服务',
      loginButton: '立即登录',
      demoPay: '模拟支付',
      destinationPlaceholder: '您要去哪里？',
      pickUp: '上车',
      recommendPickup: '推荐上车点',
      recommendDropOff: '推荐下车点',
      cannotUsePoint: '禁止载客区',
      categories: {
        recommend: '推荐',
        transportation: '交通',
        attraction: '景点',
        experience: '体验'
      },
      viewDetail: '查看',
      status: {
        finding: {
          title: '正在呼叫车辆',
          subtitle: '请您耐心等待'
        },
        dispatching: {
          subtitle: '司机马上就到'
        },
        pickUpArrived: {
          subtitle: '司机到达, 请尽快联系'
        },
        onDeliver: {
          subtitle: '车辆行驶中'
        }
      }
    },
    pickup: {
      searchPlaceholder: '请输入上车点',
      recommendTitle: '为您推荐附近的上车点',
      historyTitle: '历史记录',
      empty: '暂无数据',
      emptyTip: '换个词试试，或用"地图选点"即刻发单',
      emptyButton: '地图选点',
      deleteConfirm: '确认删除吗？',
      deleteConfirmRecall: '确认',
      deleteConfirmCancel: '取消'
    },
    pointChange: {
      searchUpPlaceholder: '请输入上车点',
      searchDownPlaceholder: '请输入下车点',
      title: '上车点变更',
      titleDropOff: '下车点变更',
      description: '拖动地图指针，可更改上车位置',
      descriptionDropOff: '拖动地图指针，可更改下车位置',
      confirm: '确认上车点',
      confirmDropOff: '确认下车点'
    },
    dropOff: {
      searchPlaceholder: '请输入下车点',
      recommendTitle: '为您推荐附近的上车点',
      historyTitle: '历史记录',
      empty: '暂无数据',
      emptyTip: '换个词试试，或用"地图选点"即刻发单',
      emptyButton: '地图选点',
      emptyRecord: '暂无历史数据',
      emptyRecordTip: '快去搜索框输入目的地吧'
    },
    orderConfirm: {
      title: '现在打车，上车更快！',
      type: '经济型',
      carType: '经济型的士',
      confirm: '立即呼叫',
      capacity: '可乘坐{{count}}人',
      time: '约{{min}}-{{max}}分钟上车',
      estimate: '预估',
      unit: '元',
      typeCount: '共 {{count}} 种车型',
      checkOrderFailed: '检查订单状态失败，请稍后再试'
    },
    profile: {
      title: '我的',
      order: '我的行程',
      service: '客服中心',
      logout: '退出登录',
      userName: '微信用户',
      goToLogin: '登录微信账户'
    },
    order: {
      all: '全部',
      succeed: '已完成',
      pending: '待支付',
      canceled: '已取消',
      empty: '暂无订单',
      emptyTip: '快去呼叫用车吧',
      noMore: '没有更多了',
      orderId: '订单号',
      carType: {
        economy: '经济型',
        business: '商务型',
        luxury: '豪华型'
      },
      status: {
        [OrderStatus.Succeed]: '已完成',
        [OrderStatus.Canceled]: '已取消',
        [OrderStatus.Finding]: '呼叫中',
        [OrderStatus.DispatchFailed]: '呼叫中',
        [OrderStatus.Dispatching]: '行程中',
        [OrderStatus.PickUpArrived]: '行程中',
        [OrderStatus.OnDeliver]: '行程中',
        pending: '待支付'
      },
      money: '{{amount}} 元'
    },
    payment: {
      title: '预付车费',
      description:
        '为确保顺利发单，请您按照预估价支付车费，行程结束后按实际费用多退少补。',
      wechat: '微信支付',
      wechatDesc: '使用人民币支付，适用于大陆微信用户',
      agreement: '我已认真阅读并同意',
      agreementLink: '《预付款协议》',
      confirm: '预支付{{amount}}元，立即叫车',
      money: '{{amount}}<span>元</span>'
    },
    prepayDetail: {
      title: '车费预估说明',
      subtitle: '应答快、接驾快、性价比优选',
      feeCard: {
        title: '费用明细',
        distanceFee: '里程费',
        arrangeFee: '安排费',
        totalAmount: '合计金额',
        unit: '元',
        notes: {
          estimation:
            '预估价根据实际交通状况、预计行驶里程、时间以及汇率波动等因素估算得出，存在波动，仅供参考。',
          additionalFee:
            '高速费、路桥费等行程中实际产生的其他附加费用不包含在预估价中，最终以行程结束后实际结算金额为准。'
        }
      },
      faq: {
        title: '常见问题',
        questionPrefix: '问',
        answerPrefix: '答'
      }
    },
    cancelOrder: {
      confirmCancel: {
        title: '确认取消订单',
        warning: '当您取消订单时，可能会产生取消费用，请您确认是否要取消订单？',
        note: '为维护良好打车生态，频繁取消可能会被限制下单，感谢您的支持和理解！',
        viewRules: '查看取消规则',
        waitMore: '我再等等',
        confirm: '取消订单'
      },
      selectReason: {
        title: '选择取消原因',
        submit: '确认提交',
        otherReason: '其他原因',
        customReasonPlaceholder: '如有其他原因，请在这里告诉我们',
        reasons: {
          platform: {
            title: '平台相关',
            content: {
              tooFar: '平台派单太远',
              inaccurateLocation: '定位不准/找不到上车位置',
              notExpectedCar: '不是我预期的车型'
            }
          },
          driver: {
            title: '司机相关',
            content: {
              driverNotComing: '司机不来接我（车不动/离我越来越远）',
              driverNotContactable: '联系不上/找不到司机',
              driverCancel: '司机要求取消订单',
              driverLate: '司机迟到',
              driverNotMatch: '不是订单显示车辆或司机'
            }
          },
          self: {
            title: '自身原因',
            content: {
              selfChange: '自身行程有变，暂时不用车',
              otherPlatform: '在其他平台打到车',
              cannotArrive: '无法及时赶到上车点'
            }
          }
        }
      }
    },
    orderDetail: {
      empty: '暂无数据',
      finding: {
        title: '正在呼叫车辆...',
        estimatedTime: '预计{{time}}分钟内被应答，已等',
        cancelButton: '取消订单'
      },
      canceled: {
        title: '订单已取消',
        subtitle: '如有需要，可重新呼叫用车',
        provider: 'Mobility Technologies 提供本次服务',
        customerService: '联系客服',
        paymentDetail: '预付款详情',
        recallButton: '重新叫车'
      },
      succeed: {
        title: '行程已结束',
        provider: 'Mobility Technologies 提供本次服务',
        actualPay: '实付',
        unit: '元',
        customerService: '联系客服',
        getInvoice: '获取收据',
        drivingFee: '行程费金额',
        prepaidAmount: '预付款金额',
        additionalPaymentAmount: '补缴金额',
        prepaid: '金额结算中'
      },
      getInvoice: {
        title: '开具电子收据',
        description: '电子收据将以邮件附件的方式发送到您的邮箱',
        invoiceTitle: '发票抬头',
        invoiceTitlePlaceholder: '请输入发票抬头',
        email: '邮箱地址',
        emailPlaceholder: '请输入邮箱地址',
        emailError: '请输入正确的邮箱地址',
        remark: '备注',
        remarkPlaceholder: '请输入备注',
        submit: '提交',
        success: '电子收据已发送至您的邮箱',
        error: '收据发送失败，请稍后再试'
      },
      confirmDialog: {
        noDriver: '没有找到附近司机，请问要重新呼叫吗？',
        recall: '重新呼叫',
        cancel: '取消订单',
        driverCancel:
          '司机取消订单，本次订单已取消，自动发起退款，稍后到账至您的微信钱包',
        driverCancelConfirm: '我知道了'
      },
      rideCode: {
        title: '乘车码',
        description: '上车后,请向司机出示以下乘车码',
        waitingNumber: '待合わせ番号',
        destination: '目的地です',
        taxiNumber: '车牌号'
      }
    },
    orderReceive: {
      cardTitle: '上车后请向司机出示您的乘车码',
      viewCode: '查看乘车码',
      dispatchingText: '司机已接单',
      dispatchingDescription: '司机正在赶往上车地点',
      pickUpArrivedText: '司机已到达',
      pickUpArrivedDescription: '请您尽快联系司机上车',
      onDeliverText: '正在前往目的地',
      onDeliverDescription: '行程已开始，请系好安全带',
      serviceProvider: 'Mobility Technologies 提供本次服务',
      message: '发消息',
      cancelOrder: '取消订单',
      shareTrip: '行程分享'
    },
    repay: {
      statusText: '订单待支付',
      serviceProvider: 'Mobility Technologies 提供本次服务',
      service: '联系客服',
      Receipt: '获取收据',
      paidText: '补交',
      unit: '元',
      feeUnit: '{{amount}}元',
      repayment: '补交车费',
      drivingFee: '行程费金额',
      prepaidAmount: '预付金额'
    },
    repayDetail: {
      title: '预付款详情',
      prepaymentAmount: '预付金额',
      actualUsage: '行程结束,实际使用',
      unusedPrepayment: '未使用预付款',
      finishedRefund: '订单完成，未使用预付款原路退回',
      unusedPrepaymentRefund: '订单取消，未使用预付款原路退回',
      paymentReminder: '需补缴车费，请返回支付',
      additionalFarePaid: '订单完成，已补缴车费',
      cancelFee: '取消手续费',
      insufficientPrepaymentNotice:
        '当预付款不足以抵扣您本次出行的实际车费时，您需就不足部分继续进行支付，否则您将无法继续使用出行服务。',
      bankProcessingNote:
        '实际划账时间以银行到账时间为准,若7个工作日内未到账,请联系客服',
      cancelFeeNote:
        '配车成功后，因乘客原因取消订单的情况下，需支付取消手续费。',
      unit: '元'
    },
    chat: {
      tips: {
        templateOnly: '仅支持发送下方模板信息',
        translation: '司乘消息将被自动翻译成对方熟悉语言。'
      }
    }
  },
  components: {
    confirm: {
      confirm: '确定',
      cancel: '取消'
    }
  },
  common: {
    noData: '暂无数据'
  },
  errorCode: {
    [ErrorCode.Internal]: '系统繁忙，请稍后再试',
    [ErrorCode.Unauthorized]: '登录已过期，请重新登录',
    [ErrorCode.InvalidArgument]: '输入信息有误，请重新输入',
    [ErrorCode.PaymentFailed]: '支付失败，请重新尝试',
    [ErrorCode.PendingPaymentExist]: '您有未完成的支付订单，请先处理',
    [ErrorCode.PaymentNotCompleted]: '支付尚未完成，请继续支付',
    [ErrorCode.LoginFailed]: '登录失败，请重新尝试',
    [ErrorCode.RefundFailed]: '退款失败，请联系客服处理',
    [ErrorCode.AccessTokenExpired]: '登录已过期，请重新登录',
    [ErrorCode.TaxiNotFound]: '暂无可用车辆，请稍后再试',
    [ErrorCode.InvalidLocation]: '位置信息无效，请重新选择',
    [ErrorCode.NotCancellable]: '当前订单状态不可取消'
  }
};
