let loadingPromise: Promise<void> | null = null;

interface PlaceWithDistance extends mapkit.Place {
  id?: string;
  distance: number;
}

export function loadMapkit(token: string, language = 'zh-Hans') {
  if (loadingPromise !== null) {
    return loadingPromise;
  }

  loadingPromise = new Promise(resolve => {
    const script = document.createElement('script');
    script.addEventListener(
      'load',
      () => {
        mapkit.init({
          authorizationCallback: done => done(token),
          language
        });

        resolve();
      },
      { once: true }
    );
    script.src = 'https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js';
    script.crossOrigin = 'anonymous';
    document.head.appendChild(script);
  });
  return loadingPromise;
}

export function getJapanesePlace(id: string): Promise<string> {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const lookup = new mapkit.PlaceLookup({
      language: 'ja-JP'
    });
    lookup.getPlace(id, (error: Error, place: mapkit.Place) => {
      if (error) {
        reject(error);
      } else {
        resolve(place?.name || '');
      }
    });
  });
}

// 计算两个坐标点之间的距离
function calculateDistance(
  coord1: { latitude: number; longitude: number },
  coord2: { latitude: number; longitude: number }
): number {
  const R = 6371; // 地球半径（千米）
  const lat1 = (coord1.latitude * Math.PI) / 180;
  const lat2 = (coord2.latitude * Math.PI) / 180;
  const deltaLat = ((coord2.latitude - coord1.latitude) * Math.PI) / 180;
  const deltaLon = ((coord2.longitude - coord1.longitude) * Math.PI) / 180;

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(lat1) *
      Math.cos(lat2) *
      Math.sin(deltaLon / 2) *
      Math.sin(deltaLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}

// 根据与中心点的距离对地点进行排序
export function sortPlacesByDistance(
  places: mapkit.Place[],
  validIds: string[],
  centerCoordinate: mapkit.Coordinate
): mapkit.Place[] {
  return places
    .filter(place => validIds.includes((place as PlaceWithDistance).id || ''))
    .map(place => {
      // 计算距离的绝对值，可用于对比测试
      const distance = Math.abs(
        calculateDistance(
          {
            latitude: place.coordinate.latitude,
            longitude: place.coordinate.longitude
          },
          {
            latitude: centerCoordinate.latitude,
            longitude: centerCoordinate.longitude
          }
        )
      );
      return Object.assign(place, { distance });
    })
    .sort(
      (pre, next) =>
        (pre as PlaceWithDistance).distance -
        (next as PlaceWithDistance).distance
    );
}
