import wx from 'weixin-js-sdk';
import { auth } from '@triplabo/web/stores';
import { auth as authService } from '@triplabo/web/services';
const wechatUA = 'MicroMessenger';
const isWechat = new RegExp(wechatUA, 'i').test(navigator.userAgent);
const isDev = import.meta.env.VITE_APP_WEB_ENV === 'development';
// 检查token
const checkToken = () => {
  // 获取URL中的search部分（问号后面的部分）
  const searchParams = new URLSearchParams(window.location.search);
  const refreshToken = searchParams.get('refreshToken');
  const accessToken = searchParams.get('accessToken');

  if (refreshToken) {
    auth.setRefreshToken(refreshToken);
  }
  if (accessToken) {
    auth.setAccessToken(accessToken);
  }
};

// 登录处理
const handleLogin = async () => {
  if (isDev || !isWechat) {
    await handleLoginTest();
    return;
  }
  // 跳转到登录页面
  wx.miniProgram.navigateTo({
    url: `/pages/loginPage/index?url=${window.location.href}`
  });
};

// 登录测试
const handleLoginTest = async () => {
  const loginReq = {
    body: {
      wechat: {
        loginCode: 'o1Mns6zeQ7lx_1vzI38uZm0JfCuM', // 'paymentpending', //
        source: 'test'
      }
    }
  };
  const result = await authService.login(loginReq);
  auth.setAccessToken(result?.accessToken || '');
  auth.setRefreshToken(result?.refreshToken || '');
};

export { checkToken, handleLogin, handleLoginTest };
