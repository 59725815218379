import { Configuration, ErrorCode } from '@linktivity/triplabo-taxi';
import { auth } from '@triplabo/web/stores';
import { showToast } from '@triplabo/web/components';
import { handleLogin } from '@triplabo/web/utils';
import i18n from '@triplabo/web/i18n';

const BASE_PATH = import.meta.env.VITE_APP_WEB_API;
const AUTHORIZATION_HEADER = 'grpc-metadata-auth-token';

const getRefreshedToken = async () => {
  const token = await auth.getRefreshedToken();
  return token;
};

const handleUnauthorizedError = async (
  init: RequestInit,
  url: string,
  fetch: (input: RequestInfo, init?: RequestInit) => Promise<Response>
): Promise<Response | void> => {
  try {
    const token = await getRefreshedToken();
    if (token) {
      const requestHeaders = new Headers(init.headers);
      requestHeaders.set(AUTHORIZATION_HEADER, token);
      return fetch(url, { ...init, headers: requestHeaders });
    } else {
      handleLogin();
    }
  } catch {
    handleLogin();
  }
};

const config = new Configuration({
  basePath: BASE_PATH,
  middleware: [
    {
      pre: async context => {
        const requestHeaders: HeadersInit = new Headers({
          'Content-Type': 'application/json'
        });
        requestHeaders.append(AUTHORIZATION_HEADER, auth.accessToken || '');
        context.init.headers = requestHeaders;
      },
      post: async context => {
        const { response, url, init, fetch } = context;

        if (!response.ok) {
          try {
            const data = await response.json();
            if (data?.id === ErrorCode.AccessTokenExpired) {
              return await handleUnauthorizedError(init, url, fetch);
            } else if (data?.id === ErrorCode.Unauthorized) {
              handleLogin();
            } else {
              const errorMessage = i18n.t(`errorCode.${data?.id}`);
              if (errorMessage) {
                showToast({ message: errorMessage });
              } else {
                if (
                  data?.common?.error_description ||
                  data?.common?.error_code
                ) {
                  showToast({
                    message:
                      data?.common?.error_description ||
                      data?.common?.error_code
                  });
                }
              }
            }
            throw new Error('API error has been handled');
          } catch {
            throw new Error('Network error');
          }
        }
      }
    }
  ]
});

export default config;
