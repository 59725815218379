import React from 'react';
import { createRoot } from 'react-dom/client';
import Toast from './Toast';

const showToast = ({
  message,
  duration = 2000
}: {
  message: string;
  duration?: number;
}) => {
  try {
    const container = document.createElement('div');
    document.body.appendChild(container);
    const root = createRoot(container);

    const removeToast = () => {
      root.unmount();
      container.remove();
    };

    root.render(
      React.createElement(Toast, {
        message,
        duration,
        onClose: removeToast
      })
    );
  } catch (error) {
    console.error('Toast create error:', error);
  }
};

export default showToast;
