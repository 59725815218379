import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import router from '@triplabo/web/routes';

import '@triplabo/web/utils/wx-sdk';
import { checkToken } from './utils';

const App: React.FC = () => {
  // 所有页面入口统一做一次token检查
  useEffect(() => {
    checkToken();
  }, []);

  return <RouterProvider router={router} />;
};

export default App;
