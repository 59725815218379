import wx from 'weixin-js-sdk';
import { auth } from '@triplabo/web/services';

interface WxConfig {
  debug?: boolean;
  appId: string;
  timestamp: number;
  nonceStr: string;
  signature: string;
  jsApiList: string[];
}

export const wxSDK = {
  async init(config: WxConfig) {
    return new Promise((resolve, reject) => {
      wx.config({
        debug: import.meta.env.VITE_APP_WEB_ENV === 'development', // 开发环境开启调试模式
        appId: config.appId,
        timestamp: config.timestamp,
        nonceStr: config.nonceStr,
        signature: config.signature,
        jsApiList: ['chooseWXPay']
      });

      wx.ready(() => {
        resolve(true);
      });

      wx.error(err => {
        reject(err);
      });
    });
  }
};

(async () => {
  try {
    const signature = await auth.getSignature({
      url: window.location.href
    });

    wxSDK.init({
      appId: import.meta.env.VITE_APP_WECHAT_ID,
      timestamp: Number(signature.timestamp),
      nonceStr: signature.nonceStr || '',
      signature: signature.signature || '',
      jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
    });
  } catch (err) {
    console.error('wechat sdk init error:', err);
  }
})();
