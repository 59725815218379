import { action, observable } from 'mobx';

export interface Location {
  name?: string;
  nameJa?: string;
  address?: string;
  mapId?: string;
  administrativeArea?: string;
  locality?: string;
  pickupLatitude?: number;
  pickupLongitude?: number;
  id?: string;
  latitude: number;
  longitude: number;
  canUse?: boolean;
}

export interface TripInfo {
  pickupLocation: Location | null;
  dropoffLocation: Location | null;
}

export class TripStore {
  @observable accessor currentTrip: TripInfo = {
    pickupLocation: null,
    dropoffLocation: null
  };

  @action
  setPickupLocation(location: Location) {
    this.currentTrip.pickupLocation = location;
  }

  @action
  setDropoffLocation(location: Location) {
    this.currentTrip.dropoffLocation = location;
  }

  @action
  clearTrip() {
    this.currentTrip = {
      pickupLocation: null,
      dropoffLocation: null
    };
  }
}

const trip = new TripStore();

export default trip;
